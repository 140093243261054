<template>
  <div class="card card-custom">
    <div class="card-header align-items-center">
      <div class="card-title">
        <div class="card-label">{{ $t("news.newsDetails") }}</div>
      </div>
      <div class="card-toolbar">
        <button
          class="btn btn-secondary mr-2"
          @click="$router.push({ name: 'adminNews' })"
        >
          {{ $t("general.cancel") }}
        </button>
        <button
          class="btn btn-primary"
          :disabled="isBusy"
          @click="updateArticle"
        >
          {{ $t("general.save") }}
        </button>
      </div>
    </div>
    <v-progress-linear v-if="isBusy" indeterminate color="primary" />
    <form class="form">
      <div class="card-body">
        <div class="form-group row align-items-center">
          <label class="col-xl-3 col-lg-3 col-form-label">
            {{ $t("news.title") }}
          </label>
          <div class="col-lg-9 col-xl-6">
            <input
              v-model="$v.article.title.$model"
              type="text"
              class="form-control"
              :class="[$v.article.title.$error ? 'is-invalid' : 'is-valid']"
            />
          </div>
        </div>
        <div class="form-group row align-items-center">
          <label class="col-xl-3 col-lg-3 col-form-label">
            {{ $t("news.content") }}
          </label>
          <div class="col-lg-9 col-xl-6">
            <quill-editor
              ref="myTextEditor"
              class="editor"
              :options="editorOptions"
              :value="article.content"
              @change="onEditorChange"
            />
          </div>
        </div>
        <div class="form-group row align-items-center">
          <label class="col-xl-3 col-lg-3 col-form-label">
            {{ $t("news.release") }}
          </label>
          <div class="col-lg-9 col-xl-6">
            <v-dialog v-model="showModal" width="290px">
              <template #activator="{ on, attrs }">
                <div class="d-flex align-items-center">
                  <div class="input-group">
                    <div class="input-group-prepend">
                      <span class="input-group-text">
                        <i class="fal fa-clock"></i>
                      </span>
                    </div>
                    <input
                      :value="dateFormatted"
                      class="form-control"
                      :class="[
                        $v.article.date.$error ? 'is-invalid' : 'is-valid'
                      ]"
                      v-bind="attrs"
                      v-on="on"
                    />
                  </div>
                  <div class="ml-1">
                    <Tooltip :text="$t('news.releaseHelp')" />
                  </div>
                </div>
              </template>
              <v-date-picker
                v-if="showModal"
                v-model="$v.article.date.$model"
                full-width
                header-color="#ff3554"
                color="#ff3554"
                locale="de"
              />
            </v-dialog>
          </div>
        </div>
        <div class="form-group row align-items-center">
          <label class="col-xl-3 col-lg-3 col-form-label">
            {{ $t("general.active") }}
          </label>
          <div class="col-lg-9 col-xl-6">
            <span class="switch switch-sm">
              <label>
                <input v-model="article.active" type="checkbox" />
                <span></span>
              </label>
            </span>
          </div>
        </div>
        <div class="form-group row align-items-center">
          <label class="col-xl-3 col-lg-3 col-form-label">
            {{ $t("news.author") }}
          </label>
          <div class="col-lg-9 col-xl-6">
            <v-select
              v-model="$v.article.user_id.$model"
              class="form-control"
              :class="[$v.article.user_id.$error ? 'is-invalid' : 'is-valid']"
              :items="users"
              item-text="name"
              item-value="id"
              :menu-props="{ offsetY: true }"
            />
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import News from "@/components/Admins/News/news";
import Admins from "@/components/Admins/Account/Admin/admins";
import Tooltip from "@/components/Tools/Tooltip";
import { formatDate } from "@/components/Tools/modifiers";
import { required } from "vuelidate/lib/validators";

export default {
  components: { Tooltip },
  props: [],
  data() {
    return {
      article: {
        title: "",
        content: "",
        user_id: null,
        date: "",
        active: false
      },
      users: [],
      isBusy: false,
      editorOptions: {
        placeholder: this.$t("projects.description"),
        theme: "snow",
        modules: {
          imageResize: {},
          toolbar: [
            ["bold", "italic", "underline", "strike", "image"],
            ["formula", "clean"],
            ["blockquote", "code-block"],
            [{ list: "ordered" }, { list: "bullet" }],
            [{ script: "sub" }, { script: "super" }],
            [{ size: ["small", false, "large", "huge"] }],
            [{ header: [1, 2, 3, 4, 5, 6, false] }],
            [{ color: [] }, { background: [] }],
            [{ align: [] }],
            [{ direction: "rtl" }]
          ]
        }
      },
      showModal: false
    };
  },
  validations: {
    article: {
      title: { required },
      user_id: { required },
      date: { required }
    }
  },
  computed: {
    dateFormatted: function () {
      return this.article.date
        ? formatDate(this.article.date, "DD.MM.YYYY")
        : "";
    }
  },
  mounted() {
    this.setBreadcrumbs();
    this.getNewsArticle();
    this.loadUsers();
  },
  methods: {
    setBreadcrumbs() {
      this.$store.dispatch(SET_BREADCRUMB, [
        {
          title: this.$t("menu.adminNews"),
          route: "/admin/news"
        },
        {
          title: this.article.title,
          route: "/admin/news/" + this.$route.params.id
        }
      ]);
    },
    getNewsArticle() {
      this.isBusy = true;
      let id = this.$route.params.id;
      News.get(id)
        .then(response => {
          this.article = response.data.data;
          this.setBreadcrumbs();
          this.isBusy = false;
        })
        .catch(error => {
          this.$swal.fire({
            icon: "error",
            title: this.$t("general.error"),
            text: error.response.data.message
          });
          this.isBusy = false;
        });
    },
    updateArticle() {
      this.$v.$touch();
      if (this.$v.$invalid)
        return this.$toast.fire({
          icon: "error",
          title: this.$t("validation.pleaseCorrectData")
        });
      this.isBusy = true;
      const data = this.article;
      News.update(data.id, data)
        .then(response => {
          if (![200, 201].includes(response.status)) {
            this.isBusy = false;
            return this.$swal.fire({
              icon: "error",
              title: this.$t("general.caution"),
              text: this.$t("validation.pleaseCorrectData")
            });
          }
          this.article = response.data.data;
          this.$toast.fire({
            icon: "success",
            title: this.$t("news.createSuccess")
          });
          this.setBreadcrumbs();
          this.isBusy = false;
        })
        .catch(error => {
          this.$swal.fire({
            icon: "error",
            title: this.$t("general.error"),
            text: error.response.data.message
          });
          this.isBusy = false;
        });
    },
    loadUsers() {
      this.isBusy = true;
      Admins.getAll({ size: 99 })
        .then(response => {
          let users = response.data.data;
          users.unshift({
            name: this.$t("news.selectPlaceholder"),
            id: null,
            disabled: true,
            selected: true
          });
          this.users = users;
          this.isBusy = false;
        })
        .catch(error => {
          this.$swal.fire({
            icon: "error",
            title: this.$t("general.error"),
            text: error.response.data.message
          });
          this.isBusy = false;
        });
    },
    onEditorChange(value) {
      this.article.content = value.html;
    }
  }
};
</script>

<style>
.ql-editor {
  overflow-y: scroll;
  resize: vertical;
}
</style>
