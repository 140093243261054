<template>
  <div>
    <NewsCreate v-if="$route.name === 'adminNewsCreate'" />
    <NewsDetails v-else-if="$route.name === 'adminNewsDetails'" />
    <NewsTable v-else />
  </div>
</template>

<script>
import NewsCreate from "@/components/Admins/News/Create";
import NewsDetails from "@/components/Admins/News/Details";
import NewsTable from "@/components/Admins/News/Table";

export default {
  components: { NewsCreate, NewsDetails, NewsTable },
  props: [],
  data() {
    return {};
  },
  mounted() {},
  methods: {}
};
</script>
