<template>
  <div id="clientsCard" class="card card-custom grid-stack-item-content">
    <div class="card-header" style="cursor: move">
      <div class="card-title">
        <h3 class="card-label">{{ $t("news.news") }}</h3>
      </div>
      <div class="card-toolbar">
        <button
          class="btn btn-primary btn-hover"
          @click="$router.push({ name: 'adminNewsCreate' })"
        >
          <i class="fal fa-plus"></i>
          {{ $t("news.newsCreate") }}
        </button>
      </div>
    </div>
    <v-progress-linear v-if="isBusy" indeterminate color="primary" />
    <div class="card-body column">
      <TableWrapper :meta="meta" @reload-data="loadNews">
        <b-table
          responsive
          :items="news"
          :fields="fields"
          class="dataTable table table-head-custom"
          :show-empty="true"
          :empty-text="$t('table.noRecords')"
        >
          <template #cell(actions)="data">
            <b
              class="btn btn-icon btn-light btn-sm mr-1"
              @click="showDetails(data.item.id)"
            >
              <i class="fal fa-pen"></i>
            </b>
            <b
              class="btn btn-icon btn-light btn-sm mr-1"
              @click="deleteNewsArticle(data.item)"
            >
              <i class="fal fa-trash"></i>
            </b>
          </template>
          <template #cell(active)="data">
            <span
              class="label label-lg label-light-success label-inline cursor-pointer"
              :class="[
                data.item.active ? 'label-light-success' : 'label-light-danger'
              ]"
              @click="updateActiveState(data.item)"
            >
              {{
                data.item.active ? $t("general.active") : $t("general.inactive")
              }}
            </span>
          </template>
        </b-table>
      </TableWrapper>
    </div>
  </div>
</template>

<script>
import Swal from "sweetalert2";
import { formatDate } from "@/components/Tools/modifiers";
import News from "@/components/Admins/News/news";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import TableWrapper from "@/components/Tools/TableWrapper";
import { mapGetters } from "vuex";

export default {
  components: { TableWrapper },
  data() {
    return {
      news: [],
      fields: [
        {
          key: "id",
          label: this.$t("table.id"),
          sortable: true
        },
        {
          key: "title",
          label: this.$t("table.title"),
          sortable: true
        },
        {
          key: "author",
          formatter: author => author.name,
          label: this.$t("table.author"),
          sortable: true
        },
        {
          key: "active",
          label: this.$t("table.status"),
          sortable: true
        },
        {
          key: "date",
          formatter: "formatDateAssigned",
          label: this.$t("table.release"),
          sortable: true,
          thStyle: { width: "180px" }
        },
        {
          key: "actions",
          label: this.$t("table.actions"),
          sortable: false,
          thStyle: { width: "130px" }
        }
      ],
      // Meta info
      meta: {},
      isBusy: false
    };
  },
  computed: {
    ...mapGetters("route", ["requestParams", "cancelToken"])
  },
  mounted() {
    this.loadNews();
    this.setBreadcrumbs();
  },
  methods: {
    setBreadcrumbs() {
      this.$store.dispatch(SET_BREADCRUMB, [
        {
          title: this.$t("menu.adminNews"),
          route: "/admin/news"
        }
      ]);
    },
    loadNews() {
      this.isBusy = true;
      let params = this.requestParams();
      let cancelToken = this.cancelToken();
      News.getAll(params, cancelToken)
        .then(response => {
          this.news = response.data.data;
          this.meta = response.data.meta;
          this.isBusy = false;
        })
        .catch(error => {
          this.$error(error);
          this.isBusy = this.axios.isCancel(error);
        });
    },
    formatDateAssigned(value) {
      return formatDate(value, "DD.MM.YYYY");
    },
    showDetails(id) {
      this.$router.push({ name: "adminNewsDetails", params: { id: id } });
    },
    deleteNewsArticle(article) {
      Swal.fire({
        title: this.$t("news.deleteTitle"),
        text: this.$t("news.deleteContent", { title: article.title }),
        icon: "warning",
        showCancelButton: true,
        reverseButtons: true,
        confirmButtonColor: "#ff3554",
        cancelButtonText: this.$t("general.cancel"),
        confirmButtonText: this.$t("general.delete")
      }).then(result => {
        if (result.isConfirmed) {
          this.isBusy = true;
          News.delete(article.id)
            .then(() => {
              this.$toast.fire({
                title: this.$t("news.deleteSuccess"),
                icon: "success"
              });
              this.loadNews();
            })
            .catch(error => {
              this.$swal.fire({
                title: this.$t("general.error"),
                text: error.response.data.message,
                icon: "error"
              });
              this.isBusy = false;
            });
        }
      });
    },
    updateActiveState(article) {
      this.isBusy = true;
      article.active = !article.active;
      News.update(article.id, article)
        .then(() => {
          this.isBusy = false;
        })
        .catch(error => {
          this.$swal.fire({
            title: this.$t("general.error"),
            text: error.response.data.message,
            icon: "error"
          });
          article.active = !article.active;
          this.isBusy = false;
        });
    }
  }
};
</script>
